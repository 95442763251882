<template>
  <div>
    <loading :active.sync="loading"
        :can-cancel="false"
        background-color="#fff"
        :is-full-page="false">
    </loading>
    <div v-if="!loading">
      <b-row v-if="errorFlag">
        <b-col cols="12">
          <div
            class="alert alert-warning"
            role="alert">
            Estás a un solo paso,
             <b-link @click="goTo('createPets')">
              has clic aquí
            </b-link> 
            para crear el perfil de tu mascota.
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="10" offset-lg="1">
           <CCard accent-color="info">
            <CCardHeader>
              <strong>Mi perfil</strong>   
              <div class="card-header-actions">                              
                  <b-button 
                      class="mr-1"
                      size="md"
                      style="width:100%;"
                      @click="editProfile" 
                      >
                      <strong>Editar perfil</strong>                   
                  </b-button>  
              </div>                                                                                              
            </CCardHeader>
            <CCardBody>                           
                <b-row>
                  <b-col lg="6">                                  
                    <strong>Nombre: </strong>  {{userProfile.nombre}}                                                   
                  </b-col>
                  
                  <b-col lg="6" v-if="userProfile.type == 'provider'">                                  
                    <strong>Apellido: </strong>  {{userProfile.apellido}}                                                   
                  </b-col>

                  <b-col lg="12">
                    <br>
                  </b-col>   

                  <b-col lg="6">                                       
                    <strong>Celular:</strong> {{userProfile.celular}}                                                  
                  </b-col>

                  <b-col lg="12">
                    <br>
                  </b-col>                 
                 
                  <b-col lg="6">                                       
                    <strong>Email:</strong> {{userProfile.email}}                    
                  </b-col>
                  
                  <b-col lg="6" v-if="userProfile.type == 'provider'">                                   
                    <strong>Ocupacion:</strong> {{userProfile.ocupacion}}                                         
                  </b-col>

                  <b-col lg="12" v-if="userProfile.type == 'provider'">
                    <br>
                  </b-col>   
                  
                  <b-col lg="6" v-if="userProfile.type == 'provider'">                                                                                                    
                    <strong>Tipo documento:</strong> {{userProfile.tipo_documento.nombre}}                       
                  </b-col>
                  <b-col lg="6" v-if="userProfile.type == 'provider'">                                       
                    <strong>Documento:</strong> {{userProfile.documento}} 
                  </b-col>                   

                  <b-col lg="12">
                    <hr>  
                  </b-col>

                  <b-col lg="6">                                       
                    <strong>Dirección:</strong> {{userProfile.direccion}}                        
                  </b-col>     

                  <b-col lg="6">                                       
                    <strong>Barrio:</strong> {{userProfile.barrio}}                        
                  </b-col>     

                  <b-col lg="6">                                       
                    <strong>Ciudad:</strong> {{userProfile.ciudad}}
                  </b-col>    

                  <b-col lg="12">
                    <br>
                  </b-col>    
                  
               
                  <b-col lg="12" >                                                                                                                                                                                                                                                                                       
                    <GmapMap
                        :center="center"
                        :zoom="17"
                        style="height: 30rem"
                        >                                              
                    <GmapMarker
                        :key="index"
                        v-for="(m, index) in markers"
                        :position="m.position"
                        :label="m.label"
                        :title="m.title"
                        :clickable="true"
                        :draggable="m.draggable"                                                                
                        />
                        {{center}}
                  {{markers}}
                    </GmapMap>  
                    <div v-for="(marker, index) in markers">
                        <strong>Latitud:</strong>  {{marker.position.lat}} <br/>
                        <strong>Longitud:</strong>  {{marker.position.lng}} <br/>
                    </div>                                           
                  </b-col> 
                </b-row> 
            </CCardBody>
            <CCardFooter>              
              <b-button 
                class="mr-1"                      
                @click="editProfile">
                  <strong>Editar perfil</strong>                   
              </b-button>
            </CCardFooter>
          </CCard>
        </b-col>
      </b-row>
     
     
    </div>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import {userProfileMixin} from '../../mixins/userProfile';
export default {
  name: 'user_profile',
  components: {
    Loading,    
  },
  mixins: [userProfileMixin],
  data() {
    return {
        loading: true,
        profileUserId: null,
        center: {lat: null, lng: null},                      
        markers: [   
            //user                                   
            {
                position: {lat: null, lng: null},
                label: '',
                draggable: false,
                title: 'Casa',                    
            },                   
        ],
        hasCoordinates: false,
        profileUserId: null,
        userType: null,        
        errorFlag: false,
    }
  },
  created(){        
    let userId = this.$store.getters.userId
    let userProfile = JSON.parse(this.$store.getters.userProfile);  
    this.profileUserId = userId          
    if(userProfile == null) {
      this.$router.replace('/users/create')      
    }else {        
      
      let lat = userProfile.ubicacion.lat;
      let lng = userProfile.ubicacion.lng;

      this.center.lat = +lat
      this.center.lng = +lng
      this.markers[0].position.lat = +lat
      this.markers[0].position.lng = +lng     
      this.hasCoordinates = true
      this.userType = userProfile.type      
      if (userProfile.pets.length == 0)
        // this.errorFlag = true
        this.$router.replace("/pets/form/0");
    }
  },
  watch: {
    $route(to, from) {
      if (to.name.includes("Profile") && from.name.includes("Profile")){
        this.profileImageUrl = 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'
        this.loading = true
        this.ownProfile = false
        let userId = this.userId
        this.profileUserId = userId
        this.getUserProfile(userId)
      }
    }
  },
  mounted(){        
    if(this.userId != null){     
      this.getUserProfile(this.userId, false)
    }else{//user logged      
      this.getUserProfile(this.profileUserId)
    }                    
  },
  methods: {
    editProfile () {   
      if (this.userType == 'client')
        this.$router.replace('/users/create');
      else if (this.userType == 'provider')
        this.$router.replace({name: 'providers-users-create'})
    },
    goTo (to) {
      if (to == 'createPets') {
        this.$router.push({name: 'pets-index'})
      }       
    }    
  },
  
}
</script>

<style scoped>

</style>